<template>
  <van-overlay :show='showMoreHotDialog' z-index='999999999999' @click='closeMoreHotDialog'>
    <div class='foot-more-hot'>
      <div class='line'>
        <span class='title'>发现更多热门内容</span>
        <span class='close-root' @click.stop='closeMoreHotDialog'>
        <img class='close'
              src='https://cdn.9kd.com/kdnet/7de0f5becf8e4e65bffb08aab8f53830.png' />
      </span>
      </div>
      <div class='item-root' v-for='(item, i) in hotNewData' :key='item.title+i'
            @click.stop="queryoneAndShowOpen(item, 'article-detail')">
        <div class='line-item'>
          <img class='tag' :src='tagsObj[i]' />
          <div class='middle' :style="{ height: item.firstPicture ? '132px' : 'max-content'}">
            <p class='item-title'
                :style="{ height: item.firstPicture ? '4.2em' : 'max-content', lineHeight: item.firstPicture ? '1.4em' : '18px' }">
              {{ item.title }}</p>
            <span class='source'>{{ item.author.nickname }} <font
              class='hot'>{{ item.views > 9500 ? `${Math.round(item.views / 10000)}万热度` : (item.views > 950 ? `${Math.round(item.views / 1000)}千热度` : `${Math.round(item.views / 100)}百热度`)
              }}</font></span>
          </div>
          <img class='picture' :src='item.firstPicture' v-if='item.firstPicture' />
        </div>
        <div class='line-interval'></div>
      </div>
      <span class='btn-more' @click.stop='onClickMore'>查看更多</span>
    </div>
  </van-overlay>
</template>

<script>
export default {
  props: {
    showMoreHotDialog:{
      type: Boolean,
      default: false
    },
    hotNewData:{
      type: Array,
      default:() => []
    }
  },
  data() {
    return {
      tagsObj: {
        0: 'https://cdn.9kd.com/kdnet/9458020e376a4ed798cad9521eb560f6.png',
        1: 'https://cdn.9kd.com/kdnet/89b035dc2665417c8b1b9b662ffa7829.png',
        2: 'https://cdn.9kd.com/kdnet/aa171aa1e02747e79969e9f1435bec3d.png'
      }
    }
  },
  methods:{
    onClickMore() {
      this.$emit('on-click-more');
    },
    queryoneAndShowOpen(data, type) {
      this.$emit('queryone-and-show-open', data, type);
    },
    closeMoreHotDialog() {
      this.$emit('close-more-hot-dialog');
    }
  }
}
</script>

<style lang='less' scoped>
.foot-more-hot {
    width: 300px;
    height: max-content;
    max-height: 435px;
    left: calc(50% - 150px);
    top: calc(50vh - 222.5px);
    position: fixed;
    border-radius: 12px;
    background: #ffffff;

    .line {
      display: flex;
    }

    .title {
      padding-left: 40px;
      padding-top: 17px;
      flex: 1;
      color: #333333;
      font-size: 17px;
      text-align: center;
      font-weight: 600;
      display: inline-block;
      vertical-align: center;
    }

    .close-root {
      width: 40px;
      height: 40px;
    }

    .close {
      width: 30px;
      height: 30px;
      padding-top: 14px;
      display: inline-block;
    }

    .item-root {
      height: max-content;
    }

    .line-item {
      max-height: 105px;
      display: flex;
    }

    .tag {
      width: 24px;
      height: 24px;
      margin-left: 8px;
      margin-top: 15px;
    }

    .middle {
      height: max-content;
      flex: 1;
      display: flex;
      flex-direction: column;

      .item-title {
        margin-right: 15px;
        padding-top: 15px;
        position: relative;
        line-height: 1.4em;
        font-size: 15px;
        /* 3 times the line-height to show 3 lines */
        height: 4.2em;
        overflow: hidden;
      }

      /*flex: 1;
      line-height: 18px;
      margin-right: 15px;
      padding-top: 15px;
      font-size: 15px;
      color: #111111;
      word-break: break-all;
      display: inline-block;*/

      item-title::after {
        content: "...";
        font-weight: bold;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 0 20px 1px 45px;
        background: url(http://www.css88.com/wp-content/uploads/2014/09/ellipsis_bg.png) repeat-y;
      }

      .source {
        height: 16px;
        margin: unset;
        padding: unset;
        padding-bottom: 11px;
        font-size: 11px;
        color: #8C94A3;
        display: inline-block;
      }

      .hot {
        padding-left: 6px;
      }
    }

    .picture {
      width: 114px;
      height: 78px;
      margin-right: 15px;
      margin-top: 15px;
      border-radius: 4px;
      object-fit: cover;
    }

    .line-interval {
      width: 250px;
      height: 1px;
      margin-left: 35px;
      margin-right: 15px;
      background: #F3F6F7;
    }

    .btn-more {
      width: 243px;
      height: 40px;
      line-height: 40px;
      margin-left: 28px;
      margin-top: 18px;
      margin-bottom: 18px;
      color: white;
      font-size: 12px;
      text-align: center;
      display: inline-block;
      border-radius: 20px;
      background: #F7321C;
    }
  }
</style>